import {
  GET_DOWNLOAD_LINK_PENDING,
  GET_DOWNLOAD_LINK_FAILED,
  GET_DOWNLOAD_LINK_FULFILLED,
  TOGGLE_FILE_IMPORT_MODAL,
  ADD_ASPERA_DOWNLOAD_LINK_TRANSFER,
  REMOVE_ASPERA_DOWNLOAD_LINK_TRANSFER,
  UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER,
  IMPORT_FILE_INTO_ACCOUNT_PENDING,
  IMPORT_FILE_INTO_ACCOUNT_FULFILLED,
  IMPORT_FILE_INTO_ACCOUNT_FAILED,
  UPDATE_FOLDER_ID,
  GET_PACKAGE_FILES,
  PACKAGE_AND_ASPERA_DOWNLOAD
} from "@actions/download_link/"

const initialState = {
  status: "PENDING",
  import_status: "READY",
  active_import_file: null,
  active_file: null,
  aspera_downloads: {},
  imported_file_ids: [],
  folder_id: null,
  errorMsg: "",
  breadcrumb: [],
  files: {
    status: "PENDING",
    list: [],
    size: 0
  },
  multi_select_files: [],
  access_device: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DOWNLOAD_LINK_PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_DOWNLOAD_LINK_FAILED:
      return {
        ...state,
        status: action.payload.status === 401 ? "EXPIRED" : "ERROR"
      }

    case GET_DOWNLOAD_LINK_FULFILLED:
      return {
        ...state,
        status: action.payload.requires_password ? "PROTECTED" : "READY",
        ...(action.payload.requires_password
          ? {
            ...(action.payload.incorrect_password
              ? {
                errorMsg: "Incorrect password"
              }
              : {})
          }
          : {...action.payload.package, access_device: action.payload.accessDevice})
      }

    case GET_PACKAGE_FILES.PENDING:
      return {
        ...state,
        files: {
          ...state.files,
          status: "PENDING"
        }
      }

    case GET_PACKAGE_FILES.FAILED:
      return {
        ...state,
        files: {
          ...state.files,
          status: "ERROR"
        }
      }

    case GET_PACKAGE_FILES.COMPLETE:
      return {
        ...state,
        files: {
          ...state.files,
          status: "READY",
          list: action.payload.files,
          size: action.payload.size
        },
        breadcrumb: action.payload.breadcrumb
      }

    case IMPORT_FILE_INTO_ACCOUNT_PENDING:
      return {
        ...state,
        import_status: "PENDING"
      }

    case IMPORT_FILE_INTO_ACCOUNT_FAILED:
      return {
        ...state,
        import_status: "FAILED"
      }

    case IMPORT_FILE_INTO_ACCOUNT_FULFILLED:
      return {
        ...state,
        import_status: "COMPLETE",
        active_import_file: null
      }

    case TOGGLE_FILE_IMPORT_MODAL:
      return {
        ...state,
        active_import_file: action.file
      }

    case ADD_ASPERA_DOWNLOAD_LINK_TRANSFER:
      return {
        ...state,
        aspera_downloads: {
          ...state.aspera_downloads,
          [action.fileID]: {
            status: "INITIALIZING"
          }
        }
      }

    case REMOVE_ASPERA_DOWNLOAD_LINK_TRANSFER:
      const aspera_downloads = state.aspera_downloads
      delete aspera_downloads[action.fileID]
      return {
        ...state,
        aspera_downloads
      }

    case UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER:
      return {
        ...state,
        aspera_downloads: {
          ...state.aspera_downloads,
          [action.fileID]: {
            ...state.aspera_downloads[action.fileID],
            ...action.payload,
            transfer_iteration_token: state.aspera_downloads[action.fileID].transfer_iteration_token
              ? state.aspera_downloads[action.fileID].transfer_iteration_token + 1
              : null
          }
        }
      }

    case UPDATE_FOLDER_ID:
      return {
        ...state,
        folder_id: action.folderId
      }
    case PACKAGE_AND_ASPERA_DOWNLOAD:
      return {
        ...state,
        multi_select_files: action.files
      }
    default:
      return state
  }
}
