import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateFile, saveFile } from "@api/project/files/"
import { can, MODIFY_PROJECTS } from "@src/helpers/authorization"

class Title extends React.Component {
  constructor(props) {
    super(props)
    this.state = { edit: false }
    this.keydown = this.keydown.bind(this)
  }
  componentDidMount() {
    window.addEventListener("keydown", this.keydown)
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.keydown)
  }
  keydown(e) {
    if (this.state.edit) {
      if (e.keyCode === 27 || e.keyCode === 13) this.save(e.target.value)
    }
  }
  save(val) {
    this.props.saveFile(this.props.file._id, "name", val || "File")
    this.setState({ edit: false })
  }
  render() {
    const { file, updateFile } = this.props
    return (
      <div title={file.name} className='flex-auto truncate'>
        {this.state.edit ? (
          <input
            value={file.name}
            autoFocus
            className='cs-input small-input'
            onChange={({ target: { value } }) => updateFile(file._id, "name", value || " ")}
            onBlur={({ target: { value } }) => this.save(value)}/>
        ) : (
          <div style={{ color: "inherit" }} className='truncate'>
            <span
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                this.setState({ edit: true })
              }}>
              {file.name}
            </span>
          </div>
        )}
        {file.tags && Array.isArray(file.tags) && file.tags.length > 0 ? (
          <div className='flex items-center'>
            <span
              style={{ fontSize: ".8em", transform: "rotate(-45deg)" }}
              className={`material-icons inline-block red`}>
              label
            </span>
            {file.tags.map((tag, i) => (
              <small key={tag._id} className='red'>
                &nbsp;
                {tag.name}
                {i + 1 < file.tags.length && ","}
              </small>
            ))}
          </div>
        ) : null}

        {!!file.frozen_at && <strong className='orange block small'>Frozen</strong>}
      </div>
    )
  }
}

Title.propTypes = {
  file: PropTypes.object.isRequired,
  updateFile: PropTypes.func.isRequired,
  saveFile: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  canEdit: can(state.user.auth, [MODIFY_PROJECTS])
})

const mapDispatchToProps = dispatch => ({
  updateFile: bindActionCreators(updateFile, dispatch),
  saveFile: bindActionCreators(saveFile, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Title)
