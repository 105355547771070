import { ADD_TRANSFER, TRANSFER_PROGRESS, UPDATE_CHILD_TRANSFER } from "@actions/transfers/"
import { updateUpload, completeUpload } from "@api/transfer/upload"
import { updateTransfer } from "@api/transfer/"
import { addGlobalMessage } from "@api/utils/"
import { S3_FINE_UPLOADER_DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"
import { validateFineUploaderParams, parseFineUploaderData, createTransfer, getFileData } from "../utils"

/**
 * Initialize Fine Uploader
 *
 * @param {Object} file
 * @param {String} databaseFileType
 * @param {String} projectID
 * @param {Object} postData - optional data to include in the http request
 * @param {Function} completionCallback - called after upload completion
 * @param {Function} progressCallback - called during upload progress
 * @param {String} pendingSourceFileID - used for uploading source files that are needed for a DCP
 *
 * @returns None
 */

export const initializeHttpUpload = (file, specifications = {}, completionCallback = null, progressCallback = null) => (
  dispatch,
  getState
) => {
  // The max file size for HTTP into s3 is 200gb
  if (file.size >= 214748364800) {
    return alert("The max file upload size is 200GB")
  }

  /**
   * Validate the params supplied are correct
   * Note: This function is called from many places throughout the app
   */
  validateFineUploaderParams(file, specifications, completionCallback, progressCallback)

  /**
   * Parse the data needed to transfer
   */
  const { isDirectory, uuid, name, opts } = parseFineUploaderData(file, specifications)

  /**
   * Fetch creates the model in the database and returns the endpoints
   */
  dispatch(addGlobalMessage(`Preparing to upload ${name}...`, "info", "cloud_upload"))

  fetch(`${GLOBALS.API_URL}/uploads`, opts)
    .then(res => res.json())
    .then(endpointDetails => {
      try {
        /**
         * Ensure fineuploader is attached to window for global access
         * This is needed to mange canceling transfers outside of the store scope
         * To-do: Refactor this functionality
         */
        if (!window.fineUploader) dispatch(attachS3FineUploaderToWindow(endpointDetails))

        /**
         * Add the transfer to redux state
         */

        dispatch({
          type: ADD_TRANSFER,
          uuid,
          transfer: createTransfer(endpointDetails.upload_details, uuid, completionCallback, progressCallback)
        })

        /**
         * We create an array to iterate over
         * All transfers have a parent transfer, so for single file uploads we create an array
         */

        if (!isDirectory) file = [file]

        file
          // .filter(file => {
          //   return file.type !== "application/zip"
          // })
          .map(f => {
            const { parsedFile, endpoint, fileUUID } = getFileData(
              f,
              isDirectory,
              uuid,
              endpointDetails,
              specifications || {}
            )
            const transfer = getState().transfers[uuid]

            /**
             * Add the child transfer to redux state
             */

            dispatch(
              updateTransfer(
                "childrenTransfers",
                {
                  ...transfer.childrenTransfers,
                  [fileUUID]: {
                    current_size: 0,
                    total_size: parsedFile.size,
                    uuid: fileUUID,
                    name: parsedFile.name
                  }
                },
                uuid
              )
            )

            /**
             * Add the child transfer fineuploader
             * Note: This also starts the transfer
             */

            window.fineUploader.addFiles([parsedFile], null, endpoint)
          })
      } catch (err) {
        throw new Error(err)
      }
    })
    .catch(err => {
      throw new Error(err)
    })
}

/**
 * Attach fine uploader to window for app-wide access
 * Note: This could be refactored to be tied into redux, but it seems to have performace issues
 */

const attachS3FineUploaderToWindow = endpointDetails => (dispatch, getState) => {
  const qq = require("@bitcine/fine-uploader/lib/lib/s3")

  window.fineUploader = new qq.s3.FineUploaderBasic({
    ...S3_FINE_UPLOADER_DEFAULTS,
    request: {
      ...S3_FINE_UPLOADER_DEFAULTS.request,
      accessKey: endpointDetails.s3_access_key
    },
    blobProperties: {
      name: fileID => {
        const file = window.fineUploader.getFile(fileID)
        return file.upload_details.file_name
      }
    },
    objectProperties: {
      key: fileId => {
        const file = window.fineUploader.getFile(fileId)
        return file.destination
      }
    },
    callbacks: {
      onSubmit: (fileID, name) => {
        const file = window.fineUploader.getFile(fileID)
        window.fineUploader.setParams(
          {
            "Content-Disposition": 'attachment; filename="' + encodeURI(file.name) + '"',
            "x-amz-storage-class": endpointDetails.s3_storage_class
          },
          fileID
        )
      },

      onProgress: (fileID, name, uploaded, total) => {
        const file = window.fineUploader.getFile(fileID)
        const parentTransfer = getState().transfers[file.parentUUID]

        /**
         * When a child transfer is updated it should add to its parent total transfer progress
         * The parent transfer is the one shown in the UI, and the one updated on the server
         */

        let totalUploadedSize = 0
        Object.keys(parentTransfer.childrenTransfers).forEach(key => {
          if (parentTransfer.childrenTransfers[key].uuid === file.transferUUID) {
            totalUploadedSize += parseFloat(uploaded)
          } else {
            totalUploadedSize += parseFloat(parentTransfer.childrenTransfers[key].current_size)
          }
        })

        const progress = `${Math.floor((totalUploadedSize / parentTransfer.total_size) * 100)}%`

        dispatch({
          type: TRANSFER_PROGRESS,
          transferUUID: file.transferUUID,
          current_size: parseFloat(uploaded),
          parentUUID: file.parentUUID,
          parent_current_size: totalUploadedSize,
          progress
        })

        if (parentTransfer.progressCallback) {
          parentTransfer.progressCallback({
            status: parentTransfer.status,
            current_size: totalUploadedSize,
            total_size: parentTransfer.total_size
          })
        }

        if (parentTransfer.transfer_iteration_token % 120 === 0) {
          dispatch(updateUpload(parentTransfer))
        }
      },
      onUpload: fileID => {
        const file = window.fineUploader.getFile(fileID)
        dispatch({
          type: UPDATE_CHILD_TRANSFER,
          parentUUID: file.parentUUID,
          transferUUID: file.transferUUID,
          key: "fineUploaderUUID",
          value: fileID
        })
        dispatch(updateTransfer("start_time", new Date().getTime(), file.parentUUID))
      },
      onComplete: fileID => {
        const file = window.fineUploader.getFile(fileID)
        const transfer = getState().transfers[file.parentUUID]
        /**
         * Ensure all of the child transfers are complete before marking the parent transfer complete
         * Some transfers may have many children, with some being < 10 bytes and some > 100 GB
         */

        let allTransfersComplete = true

        Object.keys(transfer.childrenTransfers).forEach(key => {
          if (transfer.childrenTransfers[key].current_size !== transfer.childrenTransfers[key].total_size) {
            allTransfersComplete = false
            return
          }
        })

        /**
         * If all of the child transfers are complete, update state and post to server
         */

        if (allTransfersComplete && transfer.status !== "Complete") {
          dispatch(
            completeUpload({
              method: "HTTP",
              uuid: file.parentUUID,
              total_size: transfer.total_size,
              name: transfer.name,
              upload_id: transfer.upload_id,
              callback: transfer.completionCallback
            })
          )
        }
      },
      onAutoRetry: (fileID, name, attemptNumber) => {
        const file = window.fineUploader.getFile(fileID)
        dispatch(updateTransfer("errorMsg", `Retrying transfer... attempt ${attemptNumber}`, file.parentUUID))
      },
      onError: (fileID, fileName, error) => {
        const file = window.fineUploader.getFile(fileID)
        dispatch(updateTransfer("status", `Error`, file.parentUUID))
        dispatch(updateTransfer("errorMsg", error, file.parentUUID))
        const parentTransfer = getState().transfers[file.parentUUID]
        dispatch(updateUpload(parentTransfer, error))
      },
      onCancel: fileID => {
        const file = window.fineUploader.getFile(fileID)
        dispatch(updateTransfer("status", `Paused`, file.parentUUID))
      }
    }
  })
}
